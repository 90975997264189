'use strict';

Gri.module({
  name: 'carousel-alpha',
  ieVersion: null,
  $el: $('.carousel-alpha'),
  container: '.carousel-alpha',
  fn: function () {
    tofas.carouselAlpha = {
      el : $('.carousel-alpha'),
      itemTag : $('.carousel-alpha .carousel-container'),
      init: function(){
        tofas.carouselAlpha.slider.init();
      },
      slider: {
        init: function() {
          tofas.carouselAlpha.itemTag.slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            arrows: false,
            responsive: [
              {
                breakpoint: 1199,
                settings: {
                  centerMode: false,
                  slidesToShow: 6,
                  variableWidth: false,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 991,
                settings: {
                  centerMode: false,
                  slidesToShow: 4,
                  variableWidth: false,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 768,
                settings: {
                  centerMode: true,
                  slidesToShow: 1,
                  variableWidth: true,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              }
            ]
          });
        }
      }
    }

    /**/

    tofas.carouselAlpha.init();
  }
});
